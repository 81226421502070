<template>
  <div class="page">

    <div class="data-table-container">

      <div class="tools-bar">
        <el-button-group class="i">
          <el-button type="primary" @click="$router.push({name: 'course-editor'})">新增</el-button>
          <el-button @click="load">刷新</el-button>
          <el-button @click="onExport">导出数据</el-button>
        </el-button-group>
        <el-select v-model="filter_klass" placeholder="请选择类别" @change="load">
          <el-option label="全部" value="全部"/>
          <el-option-group v-for="(group, key) in course_classes"
                           :key="key" :label="key">
            <el-option v-for="item in group" :key="item" :label="item" :value="item"/>
          </el-option-group>
        </el-select>
        <div class="grow"></div>
        <table-search-simple v-model="tableData_show" :table-data="tableData" :load="load"
                             :fields="['title']"/>
      </div>

      <el-table class="table" ref="table"
                v-loading="loading"
                :data="tableData_show"
                element-loading-text="获取数据中 ..."
                stripe highlight-current-row :row-class-name="setBadgeRow">
        <el-table-column align="center" label="ID" width="80" prop="id" sortable/>
        <el-table-column label="类别" prop="klass" width="120"/>
        <el-table-column label="课程名" prop="title">
          <template slot-scope="scope">
            <span :id="'course-' + scope.row.id">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="学习" width="80" prop="stat_studied" sortable/>
        <el-table-column label="浏览" width="80" prop="stat_visit" sortable/>
        <el-table-column label="评论" width="80" prop="stat_comment" sortable/>
        <el-table-column label="点赞" width="80" prop="stat_like" sortable/>
        <el-table-column label="排序" sortable width="80" prop="i_order"/>
        <el-table-column label="观看限制" width="90" prop="play_limit_user"/>
        <el-table-column width="160">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="mini" @click="onEditCourse(scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     :current-page.sync="page"
                     :page-size="30"
                     :total="total"/>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        page: 1,
        total: 1,
        loading: false,
        filter_klass: '',
        course_classes: '',
        highlight_id: parseInt(this.$route.query.highlight_id || '0'),
        tableData_show: [],
        tableData: [],
      }
    },
    created() {
      this.load()
    },
    watch: {
      tableData_show(v){
        console.log(v)

      }
    },
    methods: {
      load() {
        this.loading = true
        this.$api.request('/course/list_simple', {
          filter_klass: this.filter_klass,
          show_all: true,
        }).then(data => {
          data = data.sort((a, b) => a.id > b.id)
          this.loading = false
          // this.page = data.page
          // this.total = data.total

          if (this.highlight_id) {
            data = [...data.filter(r => r.id === this.highlight_id),
              ...data.filter(r => r.id !== this.highlight_id)]
          }

          this.tableData = data
          this.tableData_show = data
        })
        this.$api.request('/config_get').then(data => {
          this.course_classes = data.course_classes
        })

      },
      setBadgeRow({row, rowIndex}) {
        if (row.id === this.highlight_id) {
          return 'warning-row';
        }
        return '';
      },
      onFindOrders({id}) {
        this.$router.push({name: 'curses-users-stat', query: {filter_id: id}})
      },
      onEditCourse({id}) {
        window.open('#/course-editor?id='+ id)
        // this.$router.push({name: 'course-editor', query: {id}})
      },
      onExport(){
        this.$api.request('/course/export_courses_info').then(data => {
          const example = data.map(r => r.join(', ')).join('\n')

          let ele = document.createElement('a');
      ele.download = "课程数据.csv"
      ele.style.display = 'none';
      let blob = new Blob([example]);
      ele.href = URL.createObjectURL(blob);
      document.body.appendChild(ele);
      ele.click();
      document.body.removeChild(ele);
        })
      }
    }
  }
</script>

<style>
  .warning-row {
    background: oldlace !important;
  }
</style>
